import React from 'react';
import {SendTextMessage} from '../../requests'
import MessageBubble from './MessageBubble'
import slide_down from '../../images/slide_down.png'
import send_btn from '../../images/send_orange.png'
import UserStatus from '../UserStatus';
import { MainContext } from '../../contexts/MainContext';
import NumberSelector from '../NumberSelector';
import TargetRender from '../TargetRender';

export default class MessagingUI extends React.Component {
  static contextType = MainContext

  constructor() {
      super();
      this.state = {          
        filter:"",
        messages:[],
        newMessages:[],
        text:"",
        message_type:"text",
        bottom: true,
        add_target_modal:false
        
    }
  }
  
  componentDidMount(){
    this.convoBody.onscroll = ()=>{

      if(this.convoBody.offsetHeight+this.convoBody.scrollTop >= this.convoBody.scrollHeight-50)
      {
        this.props.read_conversation(this.props.conversation._id)
      }
    
      if((this.convoBody.scrollHeight)>(this.convoBody.scrollTop+this.convoBody.offsetHeight+500))
      { try{
        setTimeout(()=>
        {
          try{if((this.convoBody.scrollHeight)>(this.convoBody.scrollTop+this.convoBody.offsetHeight+500))
          {
          this.setState({bottom:false})}
          }
          catch{
          }
          }, 500)
        }
        catch{}
        }
      
      else
      {
        this.setState({bottom:true})

      }

   
  }
  this.interval =  setInterval( ()=> {
    try 
    { 
      if (this.convoBody)
      {if (document.hasFocus() && (this.convoBody.offsetHeight+this.convoBody.scrollTop >= this.convoBody.scrollHeight-50|| 
        this.props.conversation.messages.length<20 ) )    
     {
       if (this.props.conversation.messages.length>0) //conversation exists
       {
         this.props.read_conversation(this.props.conversation._id)
        }
      }
    }
    }
    catch (e) 
    {
      console.log(e)
    }
  }
    ,
      10000 )
  }
  componentWillUnmount(){
    clearInterval(this.interval);
  }
  componentDidUpdate(previous_props) {
    try{
      this.text_input_field.addEventListener('keyup', function(){
        this.style.height=0;
        this.style.height = (this.scrollHeight-30)+'px'
      },false) // Gonna be a mountain of event listeners here to clean up
    }
    catch{}
    
    if(this.props.conversation._id !== previous_props.conversation._id ||
      this.props.conversation.messages.length !== previous_props.conversation.messages.length)
    {
      setTimeout(()=>
      {
        try{
          this.scrollToBottom()
        }
        catch{}
        
      }
      ,
      30)
    }
    
  }
  
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "auto" });
  }

  handleChangeText = (event) =>{
    this.setState({text:event.target.value})

  }
  
  return = () => {
    this.props.message('0')
  }

  sendMessage = (text,type,_id)=>{
    this.setState({text:"",type:"text"})
    this.text_input_field.style.height="23px"
    const conversation ={
      _id : this.context.conversation._id,
      carrier:this.context.conversation.carrier,
      endpoints:this.context.conversation.endpoints
    }
    SendTextMessage(text,conversation,this.context.outbound_text,type,_id).then(response=>{
      console.log(response)
      const newState = this.context.conversations.map(conversation => {
        // 👇️ if id equals 2, update country property
        if (conversation._id === response.conversation._id) {
          return response.conversation;
        }
        return conversation;
      });
      this.context.update_global('conversations',newState)
      this.context.update_global('conversation',response.conversation)
      // let conversations_copy = [...this.context.conversations].map(conversation => conversation._id !== response.conversation._id)
      // this.context.update_global('conversations',conversations_copy)
    })
    if((this.convoBody.scrollHeight)<(this.convoBody.scrollTop+this.convoBody.offsetHeight+500))
    {
      setTimeout(()=>
      {
        this.scrollToBottom()
      }
      ,
      30)
    }

  }
  
  generate_conversation = () =>{
    const messages = []
    for (let i = 0; i<this.context.conversation.messages.length; i++)
    {
      messages.push(
      <MessageBubble 
      key={i} 
      _id={i} 
      src = {this.context.conversation.messages[i]['src']}
      number_of_endpoints={this.context.conversation.endpoints.length } 
      body={this.context.conversation.messages[i]['body']} 
      author={this.context.conversation.messages[i]['author']} 
      date={this.context.conversation.messages[i]['timestamp_created']} 
      pending={this.context.conversation.messages[i]['pending']|| false}
      />
      
      )
    }
    return messages
  }

  handleSubmit = (e) => {
    if (e)
    {
      e.preventDefault()
    }
    if (this.state.text.length>0)
    {
      const message_id = Math.floor(Math.random() * 100000000000000);
      this.sendMessage(this.state.text,this.state.message_type,message_id)
      this.context.update_global("sent_message_timestamp",Date.now())
      let src
      if (this.context.conversation.carrier){
        src = this.context.outbound_text
      }
      else{
        src = undefined
      }
      this.context.conversation.messages.push({
        body:this.state.text,
        pending:true,
        date:"",
        author:this.context._id,
        src:src,
        _id:message_id,
        type:this.state.message_type

      })
    }
  }
  clearConversation = () =>{
    let carrier = true
    if (this.context.team_or_contacts)
    {
      carrier = false
    }
    this.props.update_global("conversation", {
        carrier:carrier,
        messages:[],
        endpoints:[],
        _id:""
    })

    this.props.update_global("text_targets", [])

  }
  get_source = () => {
    if (this.context.conversation.carrier)
    {
      return <NumberSelector disposition="text" numbers = {this.context.permissions.inbound_text}/>
    }
    else{
      const user = this.context.user
    return <UserStatus 
    name = {user.display_name } 
    last_seen={user.last_seen || ""} 
    status = {user.status} />
    }
  }
  get_destinations= ()=>{
    let destinations = []
    
      for (const target of this.context.text_targets)
      { 
        if (this.context._id !== target)
      {
        destinations.push( <li > <TargetRender target={target} /> </li>
        )
      }
    }   
  return destinations
  }
  listenForEnter = (e)=>{
    const keyCode = e.which || e.keycode;
    if (keyCode === 13 && !e.shiftKey){
      e.preventDefault()
      this.handleSubmit()
    }
  }
  render() {  
    let scrollDown = <button onClick={this.scrollToBottom} id="slide_down"> <img height={'30px'} width={'30px'} src={slide_down} alt="slide down"></img> </button>
    return (
          <div id="conversation_body" ref={(el) => { this.convoBody = el; }}>
              {
                this.generate_conversation()
              }
              { this.state.bottom || scrollDown}

              <div id="anchor" style={{ float:"left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }}>
              </div>
              {
                (this.context.permissions.outbound_text.find(o=>o.number ===this.context.outbound_text)|| !this.context.conversation.carrier) &&
                <form id="send_msg_form" onSubmit={this.handleSubmit} >
                  <textarea autoComplete="off" ref={(el) =>{this.text_input_field = el; }} id="send_text_input" 
                  onKeyDown={this.listenForEnter} onChange={this.handleChangeText} 
                  value={this.state.text} placeholder="">
                  </textarea>
                  <input id="send_icon_button" style={{display:"none"}} type="submit" />
                  <button id="send_msg_btn" onClick={this.handleSubmit}>
                  <img id="send_icon_img" src={send_btn} alt="Send"></img>
                  </button>
                </form>
                //Extract Component ^
              }
          </div>
    );
  }
}