import React from 'react';
import { MainContext } from '../../contexts/MainContext';

export default class HomeBlock extends React.Component {
    static contextType = MainContext

    render() {
    let name = this.props.name
    if (name === "Call Logs" && this.props.count !==0 && this.props.count !==  undefined)
    {name = "Missed Calls"}
    if (name === "Messages" && this.props.count !==  0 && this.props.count !==  undefined)
    {name = "New Msgs"} 
    if (name === "Voicemail" && this.props.count !==  0 && this.props.count !==  undefined)
    {name = "New VMs"} 
    return (

    <div onClick={()=>this.props.show(this.props.state)} className="block" >
        { this.props.count? <h1 className="home_block_notification" >{this.props.count}</h1>: 
        <p></p>}
        <p className="block_feature" > <b>{name}</b> </p>
    </div>
     
    )
  }
}