const axios = require('axios');

axios.defaults.withCredentials = true;


export async function checkToken () {
    const response = await axios.get(`user/check_token`)
      return response.data
}
export async function get_sip_address () {
  const response = await axios.get(`user/get_sip_address`)
    return response.data
}
export async function get_conversations () {

  const response = await axios.get(
  `user/get_conversations`,
  
  )
  return response
}

export async function update_app () {
  const response = await axios.get(`user/update_app`,  {withCredentials: true,},)
    return response.data
}
export async function read_conversation(data) {

  const response = await axios.post(
  `user/read_conversation/`,
  data,
  
  {withCredentials: true}
  )
  return response.data
}

export async function check_voicemails() {

  const response = await axios.post(
  `user/check_voicemails`,  
  {withCredentials: true}
  )
  return response.data
}
export async function check_logs() {

  const response = await axios.post(
  `user/check_logs`,
  
  {withCredentials: true}
  )
  return response
}


export const loginPost = async ( data ) => {

    // Default options are marked with *

   const response = await axios.post(
    `user/login`,
    data,
    
    {withCredentials: true}
)
    
    return response.data
}
export const forgot_password = async ( email ) => {

  // Default options are marked with *

 const response = await axios.post(
  `user/forgot_password`,
  {email:email},
  
  {withCredentials: true}
)
  
  return response.data
}
   export const log_out = async () => {

    // Default options are marked with *

   const response = await axios.post(
    `user/log_out`,
    {},
    
    {withCredentials: true}
)
    
    return response.data
   }

export const SendTextMessage = async (text,conversation,outbound_text_number,type,_id) => {

  const data = {new_message:{body:text,_id:_id,type:type}, 
    conversation:conversation, 
    outbound_text_number:outbound_text_number,
    }
  const response = await axios.post(
    `user/send_message`,
    data,
    
    {withCredentials: true}
)
return response.data
}


export const update_status =  async (status) => {
  const data =  {'status':status}
  const response = await axios.post(
    `user/update_status`, 
    data,
    
    {withCredentials: true}
  )
  return response.data

}


export const getPushKey =  async () => {
  const response = await axios.get(
 `user/subscribe/`, 
    
    {withCredentials: true}
  )
  return response.data

}

export const update_password = async ( old_password,new_password ) => {
 const response = await axios.post(
  `user/update_password`,
  {old:old_password,new:new_password},
  
  {withCredentials: true}
) 
  return response.data
}

export const update_display_name = async ( new_name ) => {
  const response = await axios.post(
   `user/update_display_name`,
   {new_name:new_name},
   
   {withCredentials: true}
 ) 
   return response.data
 }
 
export const reset_password = async ( email ) => {
  const response = await axios.post(
   `user/reset_password`,
   {email:email},
   
   {withCredentials: true}
 ) 
   return response.data
 }

 export const new_contact = async (contact) =>{
  const response = await axios.post(
    `user/new_contact`,
    {contact:contact},
    {withCredentials: true}
  ) 
    return response.data
 }

 export const getPermissions = async () => {
  const response = await axios.get(`user/get_permissions`)
  return response.data    
}

export const deleteVM = async (ext,id) => {
  const response = await axios.get(`user/delete_voicemail/${ext}/${id}`)
  return response.data      
}

export const get_phones_groups = async (id) => {
  const response = await axios.get(`user/get_phones_groups`)
  return response.data      
}

export const update_outbound_number = async (number) =>{
  const response = await axios.post(
    `user/update_outbound_call`,
    {new_outbound:number},
    {withCredentials: true}
  ) 
    return response.data
 }