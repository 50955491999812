import envelope from '../../images/envelope.png'
const showNewMessage = (props) =>{
    if (props.new_messages.length>0)
    {
        props.update_global('messaging_app',true)
        const first_conversation = props.conversations.find((o)=> o._id === props.new_messages[0]._id)
        if (first_conversation.carrier){
            props.update_global('team_or_contacts',false)
        }
        else{
            props.update_global('team_or_contacts',true)
        }
    }   
}
export const NewMessageIcon = (props) =>{


    return <button  className="notifier" onClick={()=>showNewMessage(props)}  id="new_message_icon"><img height="20px" alt="new message"  src={envelope}/></button>
}