import { MainContext } from '../contexts/MainContext';
import React from 'react';
import * as utils from '../utils';

export default class NumberSelector extends React.Component {
    static contextType = MainContext

    componentDidMount(){
        this.setState({
            disposition:`outbound_${this.props.disposition}`
        })
    }
    get_numbers=()=>{
        let options = [<option key="empty" value="">Outbound Number</option>]
        for (const phone of this.props.phones)
        {
            if (phone.number === this.context[`outbound_${this.props.disposition}`]) //meaning outbound_call or outbound_text
            {
                options.push( <option key={phone.number} value={phone.number} selected>{phone.name || utils.format_number_for_user(phone.number)}</option>)
             }
            
            else 
            {
                options.push( <option key={phone.number} value={phone.number} >{phone.name || utils.format_number_for_user(phone.number)}</option>)
            }
        }
        return options
    }
    change = (e)=>{
        this.context.update_global(this.state.disposition,`${e.target.value}`)
    }
    render() {
        return (
            <select onChange={this.change} >
            {            
            this.get_numbers()
            }            

            </select>
       
        );
    }
}
