import React from 'react';
import DialButton from './DialButton';
import {Handset} from '../svgs/SVGs'
import clear_text_img from '../../images/remove-text.png'
import { MainContext } from '../../contexts/MainContext';

export default class DialPad extends React.Component {
  static contextType = MainContext
  constructor() {
    super();
    this.dialInput = React.createRef();
    this.state = {
      dialpad:""
  }
  
}

addToDialpad = (number)=> {
  this.setState({dialpad:this.state.dialpad+number })
  for (const session of this.context.sessions)
  {
    session.sendDTMF(number)
  }
}
clear_text = ()=>{
  this.setState({dialpad:""})
}
dialPadChange = (event)=>{
  this.setState({dialpad: event.target.value});
}
makeCall = ()=>{
      if (this.state.dialpad.length>0)
      {
      window.phone?.call(this.state.dialpad.replaceAll(" ",""), this.props.call_options)
      }
}

  render() {
    return (
      
      <div className="dialPage">
      <input id="dialPadInput" ref={this.dialInput} onChange={this.dialPadChange} value={this.state.dialpad} className="input" type="text" name="name" />
        {this.state.dialpad!=="" &&
         <button id="clear_text" style={{position: "absolute",top: "0",right: "calc(50% - 122px)"}} onClick={this.clear_text}>
          <img src={clear_text_img} alt="clear text" />
        </button>
        }
      <div className="dial_pad">
      <DialButton number="1" letters="" handler={this.addToDialpad} />
      <DialButton number="2" letters="ABC" handler={this.addToDialpad} />
      <DialButton number="3" letters="DEF" handler={this.addToDialpad} />
      <DialButton number="4" letters="GHI" handler={this.addToDialpad}/>
      <DialButton number="5" letters="JKL" handler={this.addToDialpad} />
      <DialButton number="6" letters="MNO" handler={this.addToDialpad} />
      <DialButton number="7" letters="PQRS" handler={this.addToDialpad} />
      <DialButton number="8" letters="TUV" handler={this.addToDialpad} />
      <DialButton number="9" letters="WXYZ" handler={this.addToDialpad} />
      <DialButton number="*" letters="" handler={this.addToDialpad}/>
      <DialButton number="0" letters="+" handler={this.addToDialpad} />
      <DialButton number="#" letters="" handler={this.addToDialpad} />
      </div>
      <div id="connect" >
      <button id="connectCall" onClick={this.makeCall}>
      {window.phone && <Handset height="40px" width="40px" />}
      </button>
      </div>
      </div>


    );
  }
}
