import React from 'react';
import * as requests from '../../requests';
import { MainContext } from '../../contexts/MainContext';
import { format_number_for_user } from '../../utils';

export default class Profile extends React.Component {
  static contextType = MainContext
  constructor() {
    super();
    this.state = {
      change_password:false,
      old_password:"",
      new_password:"",
      new_password2:"",
      display_name:""

    }

  }
componentDidMount = () =>{
  this.context.user.display_name && this.setState({display_name:this.context.user.display_name })
}


log_out = () => {
  requests.log_out().then(data => {
        if (data.success){
            this.props.updateToken("","","")
            window.phone.unregister()
        }
        else {
        }
    }).catch( data=> {
        
    }

      );

 };
  
  password_field_handler = (event,field) =>{
    this.setState({[field]:event.target.value})
  }
  passwords_dont_match_error = () =>{
    this.setState({passwords_dont_match:true})
    setTimeout(()=>this.setState({passwords_dont_match:false}),5000)
  }
  update_password = ()=>{
    if (this.state.new_password === this.state.new_password2) {
        requests.update_password(this.state.old_password, this.state.new_password).then(res=>{
          if (res.success){
            alert('Password Updated')
          }
          else{
            alert('Failed to Update Password')
          }
        })
    }
    else {
      this.passwords_dont_match_error()
    }
  }
  hande_name_change = (e)=>{
    this.setState({display_name:e.target.value})
  }
  update_display_name=()=>{
    requests.update_display_name(this.state.display_name).then(response=>
     { 
      alert('Name updated. Update takes up to 1 minute')})
      
  }
  get_outbound_options = ()=>{
    try{
      let options = []
      for (const phone of this.context.permissions.outbound_call){
        let selected
        phone.number.slice(-10) === this.context.user.sip_settings.outbound? selected=true:selected=false
        options.push(<option  key={phone.number} selected={selected} value={phone.number}>{phone.name || format_number_for_user(phone.number)}</option>)
      }
      return options
    }
    catch (e) {
      console.log(e)
    }
  }
  update_outbound_number = (e) =>{
    requests.update_outbound_number(e.target.value).then(res=>this.context.update_global('user',res.user))
  }
  render() {
    return (
    <div id="profile">
        <br/>
        <br/>
        Display Name
        <br/>
        <input id="profile_name" onChange={(e)=>this.hande_name_change(e)}  style={{textAlign:"center"}} value={this.state.display_name}></input>
        {this.state.display_name!==this.context.user.display_name && <button style={{marginLeft:"5px"}} className="btn" onClick={(e)=>this.update_display_name(e)}>Update</button>}
        <br/>
        <br/>
        Outbound Number
        <br/>
        <select id="select_outbound_number" style={{textAlign:"center"}} onChange={(e)=>this.update_outbound_number(e)}>
          {
           this.context.user.sip_settings && this.get_outbound_options()
          }
        </select>
        <br/>
        <br/>
        <button className="btn update_password_field" id="change_password_btn" onClick={()=> this.setState({change_password:!this.state.change_password})} style={{display:"block",textAlign:"center",margin:"auto"}}>
          Change Password
        </button>
        
        {
        this.state.change_password && 
          [
            <input key="old password" className="profile_password update_password_field" placeholder="enter password to change it" value={this.state.old_password} onChange={(e)=>this.password_field_handler(e,'old_password')} type="password" name="name" />
            ,
            this.state.old_password.length>0 && 
            [
              <input key="old password" className="profile_password update_password_field" placeholder="Enter New Password" value={this.state.new_password} onChange={(e)=>this.password_field_handler(e,'new_password')} type="password" name="name" />
              ,
              <input key="confirm new password" className="profile_password update_password_field" placeholder="Confirm New Password" value={this.state.new_password2} onChange={(e)=>this.password_field_handler(e,'new_password2')} type="password" name="name" />,
              <button  key="update button" className="btn update_password_field" onClick={this.update_password}>
              Update Password
              </button>,
              this.state.passwords_dont_match && <div  key="no match warning" style={{color:"red",fontSize:"12px"}}> New Password fields don't match </div>
            ]
          ]
        }
      <br/>
      <br/>
        <button onClick={this.log_out} className="log_out">
          LOG OUT
        </button>
        <br/>
     </div>
    );
  }
}
