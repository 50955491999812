import React from 'react';
import { MainContext } from '../contexts/MainContext';


export default class TeamContactToggle extends React.Component {
        static contextType = MainContext

    /* FUNCTIONS */
    toggle = ()=>  {
        this.context.update_global('team_or_contacts', !this.context.team_or_contacts)
    }
    get_button = () =>{  
        if (this.context.team_or_contacts === true) {
            return (<button className="toggle_team_contacts"  onClick={this.toggle}> <div className="directory_type" style={{color: "orange"}}> Team </div> <div className="directory_type" > Contacts </div> </button>)
        }
        else {
            return (<button className="toggle_team_contacts"  onClick={this.toggle}> <div className="directory_type" > Team </div> <div className="directory_type" style={{color: "orange"}}> Contacts </div> </button>)
        }
    }

    /* RENDER */
  render() {
    return (
          this.get_button()
    );
  }
}
