import React from 'react';
import { MainContext } from '../../contexts/MainContext';
import NumberSelector from '../NumberSelector';
import TargetRender from '../TargetRender';
import { Handset } from '../svgs/SVGs';
import back_btn from '../../images/back_btn.png'

export default class MessagingNav extends React.Component {
  static contextType = MainContext

    constructor() {
        super();
        this.state = {          
          filter:"",
          messages:[],
          newMessages:[],
          text:"",
          bottom: true
          
      }
    }

    clearConversation = () =>{
      setTimeout(()=>{this.context.update_global("conversation", {
          carrier:!this.context.team_or_contacts,
          messages:[],
          endpoints:[],
          _id:""
      })},0)

      this.context.update_global("text_targets", [])

    }
    get_source = () => {
      if (this.context.conversation.carrier)
      {
        return this.context.permissions && <NumberSelector disposition="text" phones = {this.context.permissions.inbound_text}/>
      }
      else{
      return
      }
    }
    get_destinations= ()=>{
      let destinations = []
        for (const target of this.context.text_targets)
       { 
         if (this.context._id !== target)
        {
          destinations.push(
            <TargetRender key={target} target={target} />
          )
        }
      }
      if (destinations.length <2)
      {
        return  destinations
      }
      else {
        return(
          <details id="see_all_targets" style={{textAlign:"left"}}>
            <summary style={{textAlign:"center"}} >See all participants ({destinations.length})</summary>
            
            <div style={{position:"relative",top:"5px",maxHeight:"150px",width:"100px",margin: "auto",overflow:'auto',border:"1px solid orange"}}>
            {destinations}
            </div>
          </details>
        )
      }
    }

  get_call_btn = () =>{
    try{
      let call_target
      if (this.context.conversation.carrier){
        call_target = this.context.text_targets[0]
      }
      else{
        let call_target_id = this.context.text_targets.find((o)=>o!==this.context.user._id)
        const target_user = this.context.team.find((o)=>o._id===call_target_id)
        call_target = target_user['extension']
      }
      return  this.context.text_targets.length >0  && this.context.text_targets.length <= 2 &&  <button style={{width:"15px", float:"right"}} onClick={()=>window.phone.call(call_target.replaceAll(" ",""),this.context.call_options)}  >{<Handset width="20px" height="20px"/>}</button>
    }
    catch{
      return 
    }
   
  }
  render() {
    return (
          <div id="messaging_ui_nav">

            {this.context.text_targets.length > 0 && <button id="exit_conversation" onClick={this.clearConversation}>
            <span id="clear_text">
            Clear
            </span>
            <img className={`back_btn`} id="clear_img" src={back_btn} alt="Back"></img>
            </button>}
            {this.get_source()}
            <span style={{maxWidth:"200px"}}>
            {this.get_destinations()}
            </span>
            {this.get_call_btn()}
          </div>
    );
  }
}