import React from 'react';
import { MainContext } from '../../contexts/MainContext';
import VMEntry from './VMEntry';
import * as config from '../../config.json'
import back_btn from '../../images/back_btn.png'

export default class VoicemailApp extends React.Component {

    static contextType = MainContext
    constructor() {
        super();
        this.state = {
          filter:"",
    }
    }
    return = () => {
      this.props.hide('voicemail_app')
    }
   createTable= () => {
        const divs = []
        for ( const vm of this.context.voicemails)
            { 
              divs.push(<VMEntry key={vm.msg_id} play_audio={this.play_audio} {...vm}  />)
              }
        return divs
      }
    play_audio = (ext,id) =>{
        this.audio_element.src = `${config.server_ip}/user/play_vm_audio/${ext}/${id}`
        this.audio_element.pause()
        this.audio_element.load()
        this.audio_element.play()
        const voicemails = [...this.context.voicemails];
        const index = this.context.voicemails.findIndex(o => o.id === id);
        voicemails[index].read = 1;
        this.context.update_global('voicemails',voicemails)
    }

  render() {
    return (
      <div id="voicemail_app">
        <button className="back" id="back_from_voicemail" onClick={this.return}>
        <img className="back_btn" src={back_btn} alt="Back"></img>
        </button>
        <div style={{height:"40px",width:"100%",background:"white",zIndex:2}}>
        </div>
        <audio ref={(el) => { this.audio_element = el; }} controls id="play_audio" style={{maxWidth:"100%",width:"400px"}} src=""></audio>
        <table style={{height:"80%",display:"block","overflowY":"auto"}} >   
            <thead>
            <tr>
                <td style={{width:"30px"}}></td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {this.createTable()}
            </tbody>  
        </table>        
      </div>
    );
  }
}
