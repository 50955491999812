import React from 'react';
import GroupMyselfToggle from '../GroupMyselfToggle';
import CallEntry from './CallEntry';
import GroupCallEntry from './GroupCallEntry';
import * as requests from '../../requests';
import back_btn from '../../images/back_btn.png'
import { MainContext } from '../../contexts/MainContext'; 
import { get_internal_name } from '../../utils';

export default class CallLogs extends React.Component {
static contextType = MainContext

componentDidMount = () =>{
  requests.check_logs().then(res =>{
    if(res.data.user){
     this.context.update_global('user',res.data.user)
     this.props.missed_call_checker(this.context.calls)
    }
  })
}
  
createMyselfTable= () =>{
    const divs = []
    divs.push(
    <thead>
      <tr key="myselfTable" className="table_row" style={{fontWeight: 'bold', textAlign:'left'}}>
        <th id="call_entry_disposition">Disposition</th>
        <th id="call_entry_source">Source</th>
        <th id="call_entry_destination">Destination</th>
        <th id="call_entry_timestamp">Date</th>
        <th id="call_entry_duration">Duration</th>
      </tr>
    </thead>)
    for ( const call of this.context.calls)
        {
          if (call.dstext === this.props.selfExt ||  call.srcext === this.props.selfExt)  {
            const dstdidname = get_internal_name(call.dstdid,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            const srcdidname = get_internal_name(call.srcdid,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            const dstextname = get_internal_name(call.dstext,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            const srcextname = get_internal_name(call.srcext,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            divs.push(<CallEntry call_options={this.context.call_options} key={call.linkedid} call={call} dstdidname={dstdidname} srcdidname={srcdidname} dstextname={dstextname} srcextname={srcextname} selfExt={this.props.selfExt} />)
          }
        }
    return divs
  }
  createGroupTable= () =>{
    const divs = []
    divs.push(
    <thead>
      <tr key="groupTable" className="table_row" style={{fontWeight: 'bold', textAlign:'left'}}>
        <th id="call_entry_disposition">Disposition</th>
        <th id="call_entry_group">Group</th>
        <th id="call_entry_source">Source</th>
        <th id="call_entry_destination">Destination</th>
        <th id="call_entry_timestamp">Date</th>
        <th id="call_entry_duration">Duration</th>
      </tr>
    </thead>)
    for ( const call of this.context.calls)
        {
          if (call.dstgroup)  {
            const groupname = get_internal_name(call.dstgroup,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            const dstdidname = get_internal_name(call.dstdid,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            const srcdidname = get_internal_name(call.srcdid,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            const dstextname = get_internal_name(call.dstext,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            const srcextname = get_internal_name(call.srcext,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
            divs.push(<GroupCallEntry call_options={this.context.call_options} key={call.linkedid} call={call} groupname={groupname} dstdidname={dstdidname} srcdidname={srcdidname} dstextname={dstextname} srcextname={srcextname} selfExt={this.props.selfExt} />)
          }
        }
    return divs
  }
  return = () => {
    this.props.hide('CallLogs')
  }
  render() {
    return (
    <div id="call_logs">
            <button className="back" id="back_from_logs" onClick={this.return}>
      <img className="back_btn" src={back_btn} alt="Back"></img>
      </button>
     <div style={{position:"fixed",width:"auto",top:"10px",height:"23px",left:"calc( 50% - 75px )",border:"1px solid orange",borderRadius:"10px",textAlign:"center"}}>
      <GroupMyselfToggle status={this.props.group_or_myself} group_myself_toggle={this.props.group_myself_toggle} />
     </div>
      <table style={{marginTop:"60px"}}>   
        {  
      this.props.group_or_myself?
      this.createMyselfTable() :
      this.createGroupTable()
      }
      </table>
      </div>
    );
  }
}
