import React from 'react';
import * as requests from '../requests';

export default class Directory extends React.Component {

  componentDidMount(){
    let token = this.getLocalToken()
    requests.checkToken(token).then(res => {
        console.log(res)
        if (res['token'] ==='none' || res['token'] ==='expired')
        {console.log (token)
          token = "";
        console.log(token)}
        this.props.setToken(token)

      }
        )
}
  render() {
    return (
      <div className="notification">


      </div>


    );
  }
}
