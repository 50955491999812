import React from 'react';

export default class UserStatus extends React.Component {

    get_status = () =>{

        if (this.props.status === 2)
        {return 'limegreen'}
        if (this.props.status === 1)
        {return 'orange'}
        if (this.props.status === 0)
        {return 'red'}
        }


  render() {

    return (
      <div className="team_user contact_or_user"  style={{textDecorationColor:this.get_status(),width:this.props.width, background:this.props.background}}>
        {this.props.name}
      </div>
    );
  }
}
