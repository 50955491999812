import { MainContext } from '../contexts/MainContext';
import React from 'react';
import UserStatus from './UserStatus';
import ContactDisplay from './ContactDisplay';

export default class TargetRender extends React.Component {
    static contextType = MainContext

    get_contact = () =>{
        try{
       const contact =  this.context.user.contacts.find(o => o.number === this.props.target)
       return <ContactDisplay 
       name = {contact.name}
       number={contact.number}
         />
        }
        catch{
            return <ContactDisplay 
            name = ""
            number={this.props.target}
              />
        }
    }
    get_user = ()=>{
        try {
        const user = this.context.team.find(o => o._id ===this.props.target)
        return <UserStatus 
        name = {user.display_name || user.first_name} 
        last_seen={user.last_seen || ""} 
        status = {user.status}
        width = "100%"
        background = "white" />
        
        }
        catch{}
    }
    
   
    render() {
        let render_target = this.get_user
        if (this.context.conversation.carrier)
        {
            render_target = this.get_contact
        }

        return ( 
             
                render_target()   
            
        )
    }
}
