import React from 'react';
import { format_number_for_user } from '../utils';

export default class ContactDisplay extends React.Component {



  render() {

    return (
      <span className="messaging_nav_contact_span" style={{textTransform:"capitalize",height:"50px"}}>
        {this.props.name.length>0?
        <l style={{textTransform:"capitalize",height:"25px",lineHeight:'25px',display:'block',position: 'relative',top: '5px'}}>
        {this.props.name}
        </l>:
        ""
        }
        <l  style={{color:"orange",fontSize:"12px",height:"20px",position: 'relative'}}>
        {format_number_for_user(this.props.number)}
        </l>
      </span>
    );
  }
}
