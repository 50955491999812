import React from 'react';
import { MainContext } from '../contexts/MainContext';


export default class GroupMyselfToggle extends React.Component {
        static contextType = MainContext

    /* FUNCTIONS */
    toggle = ()=>  {
        this.context.update_global('group_or_myself', !this.context.group_or_myself)
    }
    get_button = () =>{  
        if (this.context.group_or_myself === true) {
            return (<button className="toggle_group_myself"  onClick={this.toggle}> <div className="directory_type" style={{color: "orange"}}> Me </div> <div className="directory_type" > Groups </div> </button>)
        }
        else {
            return (<button className="toggle_group_myself"  onClick={this.toggle}> <div className="directory_type" > Me </div> <div className="directory_type" style={{color: "orange"}}> Groups </div> </button>)
        }
    }

    /* RENDER */
  render() {
    return (
          this.get_button()
    );
  }
}
