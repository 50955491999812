import React from 'react';
import { deleteVM } from '../../requests';
import { MainContext } from '../../contexts/MainContext';
import { get_internal_name } from '../../utils';
export default class VMEntry extends React.Component {
  static contextType = MainContext
  delete = ()=>{
    this.context.update_global('loading',true)
    deleteVM(this.props.mailboxuser,this.props.id).then(res => 
      {if (res.voicemails){
        this.context.update_global('voicemails',res.voicemails)
        this.context.update_global('loading',false)
      }})
  }
  render() {
    let new_vm = ""
    if (!this.props.read){
      new_vm = "new_row"
    }
    return (
    <tr className={`table_row ${new_vm}`} style={{height:"60px", textAlign:"center"}}>
      <td style={{width:"30px"}}>
        <button style={{width:"50px",height:"50px"}} onClick={()=>this.props.play_audio(this.props.mailboxuser,this.props.id)}>▶</button>
      </td>
      <td>
        {get_internal_name(this.props.callerid,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)}
      </td>
      <td>
        {get_internal_name(this.props.mailboxuser,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)}
      </td>
      <td>
        {this.props.duration}s
      </td>
      <td>
        {new Date(parseInt(this.props.origtime)*1000).toLocaleString()}
      </td>
      <td>
        <button onClick={this.delete}>🗑</button>
      </td>
    </tr>
    );
  }
}
