import React from 'react';
import {Handset} from '../svgs/SVGs'
import {OpenText} from '../svgs/SVGs'
import { MainContext } from '../../contexts/MainContext';
import * as utils from '../../utils';

export default class Contact extends React.Component {
  static contextType = MainContext

  constructor() {
    super();
    this.state = {
      

  }
}
  makeCall = ()=>{
    window.phone.call(this.props.number.replaceAll(" ",""),this.context.call_options)
  }

  render() {
    return (
      <div className="contact">
      <div className="contact_or_user">
        {this.props.name} <p style={{display:"inline",fontSize:"15px",color:"orange",letterSpacing:"2px"}}> {utils.format_number_for_user(this.props.number)}</p>
      </div>        
      <div className="connect"> 
          <button  onClick={this.makeCall}> 
          <Handset height="40px" width="40px" />
          </button>
          <button onClick={()=> (this.props.go_to_conversation(this.context._id,[this.props.number])) }> 
            <OpenText/>
          </button>
        </div>
      </div>
    );
  }
}
