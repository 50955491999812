import React from 'react';
import ConversationTab from './ConversationTab';
import MessagingUI from './MessagingUI.js'
import TeamContactToggle from '../TeamContactToggle';
import { MainContext } from '../../contexts/MainContext';
import MessagingNav from './MessagingNav';
import back_btn from '../../images/back_btn.png'
import AddTargetModal from '../AddTargetModal';

export default class MessagingApp extends React.Component {


  static contextType = MainContext
    constructor() {
        super();
        this.state = {
          filter:"",
          add_target_modal:false
    }
    }
    return = () => {
      this.props.hide('messaging_app')
    }
    
    createConversationsTabs = (team_or_contacts) => {
      const tabs = []
      let conversations = [...this.props.conversations]
      conversations.sort(function(a,b)
      {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.last_updated) - new Date(a.last_updated);
      });
      for ( const conversation of this.context.conversations)
        { if (conversation.carrier !== team_or_contacts)
          
          { 
            let source=[]
            if (team_or_contacts) //Team
              {
              tabs.push(
              <ConversationTab 
                key={conversation._id} 
                conversation={conversation}
                update_endpoints={this.props.update_endpoints}
              />
              )   
              }
              else        //Contacts
              { 
                for (const number of conversation.endpoints)
                {
                if (this.props.permissions.inbound_text.find(o=>o.number === number)) 
                  source.push(number)
                }
                tabs.push(
                <ConversationTab 
                  key={conversation._id} 
                  id = {this.context._id} 
                  conversation={conversation}
                  update_endpoints={this.props.update_endpoints}
                  source = {source}
                  inbound_permission = {this.props.permissions.inbound_text}
                />
                )  
              }
          }
        }
      return tabs
    }
  get_open_conversation = ()=>{
    if(this.context.conversation._id)
    return ("open_conversation") 
    else
    return ""

  }
  add_target_to_text_targets = (target,carrier) => {
    if (carrier) 
    {   let contact
        contact =  this.context.user.contacts.find(o => o.number === target)
        if (!contact) 
        {   if(this.context.text_targets.length===0)
            if (target.length === 10) 
            {
                if (parseInt(target))
                {   const new_targets = [...this.context.text_targets]
                    new_targets.push(`+1${target}`) //international flag
                    this.context.update_global
                    ("text_targets", new_targets)
                    return true

                }
            }
        }
        else
        {
            const new_targets = [...this.context.text_targets]
            new_targets.push(target)
            this.context.update_global
            ("text_targets", new_targets)
            return true
        }
        return false
    }
    else
    {
        let user
        user =  this.context.team.find(o => o._id === target)
        if (user)
        {
            const new_targets = [...this.context.text_targets]
            new_targets.push(target)
            this.context.update_global
            ("text_targets", new_targets)
            this.props.hide_add_field && this.props.hide_add_field()
          
            return true

        }
        return false
    }

}

  add_target_function = (e)=>{
    const response = this.add_target_to_text_targets(e.target.value,this.context.conversation.carrier)
    if (response)
    {this.props.hide_add_field && this.props.hide_add_field()}
  }
  render() {
    return (
      <div id="messaging_app">
          <button className={`back ${this.get_open_conversation()}`} id="back_from_messagingApp" onClick={this.return}>
          <img className={`back_btn`} src={back_btn} alt="Back"></img>
          </button>
          {!(this.context.text_targets.length>0 && this.context.conversation.carrier) &&
            [
            this.state.add_target_modal && <AddTargetModal event_function={this.add_target_function} style={{position:"absolute",right:"10px",top:"80px"}} hide_add_field={()=>this.setState({add_target_modal:false})}/>,
            this.state.add_target_modal|| <button  onClick={()=> {this.setState({add_target_modal:!this.state.add_target_modal})}} id="add_target">Add</button>,
            this.state.add_target_modal && <button  onClick={()=> {this.setState({add_target_modal:!this.state.add_target_modal})}} id="add_target">Cancel</button>
          ]

            }
          <div id="tabs_nav">
          <TeamContactToggle status={this.props.team_or_contacts} team_contact_toggle={this.props.team_contact_toggle} />
          </div>
          <div id="conversation_tabs">
            {
              this.props.conversations && this.createConversationsTabs(this.props.team_or_contacts)
            }
          </div>
          <div className={this.context.text_targets.length>0 ? "fixed" : undefined} id="messaging_ui">
          {
            <MessagingNav />
          }
          {
          this.context.text_targets && this.context.text_targets.length>0  && 
          <MessagingUI key="messagingui" 
          update_global={this.props.update_global} 
          team={this.props.team} 
          _id={this.props._id} 
          conversation = {this.props.conversation} 
          read_conversation = {this.props.read_conversation}
           /> 
          
          }
          </div>
      </div>
    );
  }
}
