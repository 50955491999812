import { MainContext } from '../contexts/MainContext';
import React from 'react';
import * as utils from '../utils'

export default class AddTargetModal extends React.Component {
    static contextType = MainContext

    constructor()
    {
    super();
    this.state = 
    {
    }
    }
    componentDidMount (){
        this.add_event_listeners()
    }
    componentDidUpdate(){
        this.add_event_listeners()
    }
    componentWillUnmount(){
      
    }
    add_event_listeners = ()=>{
        this.desitination_input_field.addEventListener('change',this.props.event_function)
        this.desitination_input_field.addEventListener('keyup', this.props.event_function)
    }
    
    create_data_list = () => {
        let list = []
        if (!this.context.team_or_contacts) //contacts
        {
            for (const contact of this.context.user.contacts)
            {   
                    try{

                        list.push(<option key={`target_modal_${contact.number}`} value={contact.number}> {contact.name||utils.format_number_for_user(contact.number)} </option>)
                    }
                    catch {
                        
                    }
                
            }
            return (
            <>
            <input className="add_target_input" placeholder="Enter 10 digit No." autoComplete="off" ref={(el) => { this.desitination_input_field = el; }} list="targets" name="add_destinations" />
            <datalist id="targets"> {list} </datalist>
            </>)
        }   
        else //team
        {
            list.push(<option key="empty" value=""></option>)
            if(this.props.groups){
                for (const group of this.context.groups){
                    list.push(<option key={`target_modal_${group._id}`} value={group._id}> {group.name||group._id} </option>)
                }
            }
            for (const user of this.context.team)
            {   
                if (user._id !== this.context._id && user.archived!==1)
                {   
                    try
                    {
                        list.push(<option key={`target_modal_${user._id}`} value={user._id}> {user.display_name||user.first_name||""} </option>)
                    }
                    
                    catch
                    {

                    }
                }
            }
            return (<select name="targets" className="add_target_input" ref={(el) => { this.desitination_input_field = el; }} > {list} </select>)
        }
        
    }
    render() {
        return (
        <div className="add_target_modal" style={this.props.style} >
            {this.create_data_list()}
        </div>
        );
    }
}
