import React from 'react';
import UserStatus from '../UserStatus'
import { MainContext } from '../../contexts/MainContext';
import { format_number_for_user } from '../../utils';

export default class ConversationTab extends React.Component {
  static contextType = MainContext
  constructor() {
      super();
      this.state = {
      new_messages : 0,
    }
  }
  
  render_names = () => {
    let div = []
    for (const endpoint of this.props.conversation.endpoints)
      {
        if (this.props.conversation.carrier) 
        {
          if (!this.props.inbound_permission.find(o=>o.number===endpoint))
            {
            const contact = this.context.user.contacts.find( o=> o.number === endpoint) ||""
              div.push(<p key="name"> {contact.name} </p>)
              div.push (<p key="number" className="conversation_tab_number" style={{}}>{contact.number && format_number_for_user(contact.number) }</p>)

              if (!contact) {
                div.push (
                <p key="formatted_number"> {format_number_for_user(endpoint)} </p>
                
                )
              }
            }
        }
        else
        { 
          if (endpoint !== this.context._id && this.context.team)
          { 
            const user = this.context.team.find( o=> o._id === endpoint)
            if (user)
            {div.push(
            <UserStatus 
            key={user._id}
            name = {user.display_name} 
            last_seen={user.last_seen || ""} 
            status = {user.status} />
            )}
          }
        }
    }
  

    return div
  }
  
  generate_new_messages_icon = () => 
  {
    try{
      let count = 0
      for (const message of this.context.new_messages){
          if (message._id === this.props.conversation._id)
          {
            count+=1 
          }
      }
      if (count>0)
      return(<div className="new_messages">{count}</div>)
    }
    catch (e) 
    {
      console.log(e)
      return 0
    }
  }
  get_selected () 
  {
    let className 
    if (this.props.conversation._id === this.context.conversation._id)
    {
      if(this.state.selected !== "selected_conversation")

        {
         className = "selected_conversation"
        }    
    }
    else 
    {
      if(this.state.selected !== "")
      {
        className= ""
      }    
    } 
    return className
  }


    
      
    
  render() 
  {
    
    
    return (
      <div className="conversation_tab" >
        {
          this.props.conversation.endpoints && this.props.conversation.endpoints.length >= 2 ?
          <button className={this.get_selected()} id={this.props._id} onClick={()=> this.context.team && this.props.update_endpoints(this.props.conversation.carrier,this.props.conversation.endpoints)}>
            {
            this.generate_new_messages_icon()
            }
            {
            this.render_names()
            } 
          </button>
          :
          this.props.conversation.endpoints && this.props.conversation.endpoints.length === 1?
          <button className={this.get_selected()} onClick={()=> this.props.update_endpoints(this.props.conversation.carrier,this.props.conversation.endpoints)}> 
            ME
          </button>:
          <button></button>
        }
      </div>
    );
  }
}
