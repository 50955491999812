import React from 'react';
import HomeBlock from './HomeBlock'
import { MainContext } from '../../contexts/MainContext';

export default class Home extends React.Component {
    static contextType = MainContext

    render() {
    
    return (
        <div id="home">
            <div className="mother_of_blocks">
                {/*<h2>User</h2>*/}
                <div className="block_slider" style={{marginBottom:this.props.marginBottom}}>
                    <HomeBlock  header="User" show={this.props.show} state={'messaging_app'} count={this.props.new_messages.length}  name={'Messages'} />    
                    <HomeBlock  header="User" show={this.props.show} state={'CallLogs'} count={this.props.missedCalls.length}  name={'Call Logs'} />     
                    <HomeBlock  header="User" show={this.props.show}  state={'voicemail_app'} count={this.context.voicemails && this.context.voicemails.filter(o=> o.read === 0).length}  name={'Voicemail'} />            
                </div>
            </div>
        </div>
    )
    }
}