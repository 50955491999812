import React from 'react';
export default class DialButton extends React.Component {
  constructor() {
    super();
    this.state = {
      
      dialpad:""
  }


}
  render() {
    return (
      <button
        className="dial_button"
        onClick = {()=> this.props.handler(this.props.number)}
      >
        <p className="dialPadNumbers">{this.props.number}</p>
        <p className="dialPadLetters">
        {this.props.letters === ""?
        <br></br>
        :
        this.props.letters}
        </p>
      </button>
    );
  }


}
