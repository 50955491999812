import React from 'react';
import { MainContext } from '../../contexts/MainContext';
import AddTargetModal from '../AddTargetModal'
import TeamContactToggle from '../TeamContactToggle';

export default class TransferCall extends React.Component
{
  static contextType = MainContext
  constructor()
  {
    super();
    this.state = 
    {
        target:""
    }
  }
    update_transfer_target = (new_target)=>{
        this.setState({"target":new_target})
    }
    transfer_call = ()=>
    {
        this.context.selected_call.refer(this.state.target)
        this.context.selected_call.terminate()
        this.context.update_global('transfer_call_modal',false)
    } 

  componentDidUpdate(){
    
  }
  transfer_function = (e)=>{
     let value = this.context.team.find((o)=> o._id === e.target.value)
                if ( value){
                    this.update_transfer_target(value.extension)
                }
                else {
                    this.update_transfer_target(e.target.value.replaceAll(" ", "").replaceAll("\t",""))
                }
  }
  render() 
  {
    return (
        <>
        {
       this.context.transfer_call_modal &&  this.context.selected_call&& 
       <div className="modal" id="transfer_call_modal">
        <div style={{"textAlign":"center"}}>
        <TeamContactToggle />
        </div>
        <AddTargetModal groups={true} event_function ={this.transfer_function} style={{"position":"relative","top":"0"}} />
        <br/>
        <button onClick={this.transfer_call} className="btn" style={{"margin":"auto","display":"block","padding":"12px","border-radius":"5px"}}>Transfer Call</button>
      </div> 
      }
      </>
    );
  }
}
