
import React from 'react';
import greenDot from '../../images/green_dot.png'
import redDot from '../../images/red_dot.png'
import orangeDot from '../../images/orange_dot.png'
import * as requests from '../../requests'

export default class Status extends React.Component {
  constructor() {
    super();
    this.state = {
      statusChangeUI: false,
    }
  }
  update = (status) => {

    requests.update_status(status).then(
      () => {
      this.props.update_status(status)
      },
      (error) => {
          return error
      }
    )
}


getIcon = (number, classname="statusOptionImg") => {
  let icon
  if (number ===2)
  { 
    icon = (<img className={classname} src={greenDot} alt="Online" ></img> )
  }
  else if (number === 1)
  { 
    icon = (<img className={classname} src={orangeDot} alt="Busy" ></img> ) 
  } 
  else
  {
    icon = (<img  className={classname} src={redDot} alt="Offline" ></img>) 
  }

  return icon
}
  
  statusChangeDiv = () => {
  
    const options = ['Offline','Busy','Online']
    const divs = [<button className="statusOption" key={this.props.status} id="statusOptionSelected">{options[this.props.status]} {this.getIcon(this.props.status)} </button>]

    for ( let i=options.length-1 ; i>=0 ; i--)
        { 
          divs.push(
            this.props.status !== i ?
              <button key={i} onClick={() => this.update(i)} className="statusOption">{options[i]} {this.getIcon(i)}</button>
              :
              ""
            )
        }
    return divs
  }
  showHideStatusChangeDiv = () => {
    this.setState({statusChangeUI: !this.state.statusChangeUI})
  }
  render() {
    return (
      <div id='status_div' className="notifier">
      <button id="status" className="statusOption status_btn_click" onClick={this.showHideStatusChangeDiv}>
      {this.getIcon(this.props.status, "statusOptionImg status_btn_click")}
      </button>
      {this.props.statusChangeUI && <div id="statusOptions"> {this.statusChangeDiv()} </div>
        }
      </div>
    )
  }
}