import React from 'react';
import usrImg from '../../images/usrImg.png'
import logo from '../../images/logo.png'
import lockImg from '../../images/lockImg.png'
import loadingImg from '../../images/loading-buffering.gif'
import * as requests from '../../requests';

export default class Login extends React.Component 
{
    constructor() 
    {
        super();
        this.state = 
        {
            email: "",
            password:"",
            forgot_password_popup:false,
            forgot_password_input:"",
        }
    }
    handleChangeEmail = (event) => 
    {
    this.setState({email:event.target.value.toLowerCase()})
    }

    handleChangePassword = (event) => 
    {
        this.setState({password:event.target.value})
    }

    handle_forgot_password = (event) =>
    {
        this.setState({forgot_password_input:event.target.value})
    }

     login = (event) => 
    {
        event.preventDefault();
        this.loading_login_gif.classList.remove('hidden');
        requests.loginPost( {email:this.state.email.trim(),password:this.state.password}).then(data => 
        {
            if (data.token){
                
                this.props.updateToken(data.token,data._id,data.user)
                this.props.check_token_for_login()
            }
            else {
            this.error_message.innerText=data.error
            this.loading_login_gif.classList.add('hidden');
            this.error_message.classList.remove('hidden');
            setTimeout( ()=> 
                {
                try {this.error_message.classList.add('hidden')}
                catch (e)
                {
                console.log (e)
                }
                }
                ,5000)
            }
        }).catch
        ( data=> 
        {
            this.error_message.innerText='Check your internet connection or contact support'
            this.loading_login_gif.classList.add('hidden');
            this.error_message.classList.remove('hidden');
           
                setTimeout( ()=> 
                {
                try {this.error_message.classList.add('hidden')}
                catch (e)
                {
                console.log (e)
                }
                }
                ,5000)
            }

        );
    }

     forgot_password_popup = ()=>{
         this.setState({"forgot_password_popup":!this.state.forgot_password_popup})
         setTimeout(()=>{if(this.state.forgot_password_popup)
         {
             this.setState({forgot_password_input:this.state.email.trim()})
         }},10)
     }
     reset_password_request = ()=>{
        this.loading_login_gif.classList.remove('hidden');
         requests.forgot_password(this.state.forgot_password_input.trim()).then(response => {
             if (response.success === true)
             {   this.loading_login_gif.classList.add('hidden');
                 alert("Check email for password reset instructions")
             }
         })
     }

    render() {
    return (
        <div>
            <form id="login" onSubmit={this.login}>
                <div id="login_background">
                <img  alt="Tonet" src={logo} />
                </div>
                <div id="login_form">
                    <img className="login_img" alt="Login" src={usrImg}></img> <br/>
                    <input className="login_input" placeholder="email@tonet.io" value={this.state.email} onChange={this.handleChangeEmail} type={this.props.type}/>
                    <br/>
                    <img className="login_img" alt="Password" src={lockImg}></img>
                    <br/>
                    <input className="login_input" placeholder="********" value={this.state.password} onChange={this.handleChangePassword} type="password"/>
                    <br/>
                    <input id="login_btn"  type="submit" value="Login" />
                    <br/>
                    <img  ref={(el) => { this.loading_login_gif = el; }} id="loading_login"  className="hidden" alt="Password" src={loadingImg}></img>
                    <br/>
                    <p ref={(el) => { this.error_message = el; }} id="login_error" className="hidden"> Check your internet connection or contact support</p>
                    <br/>
                </div>
            </form>
            <div style={{textAlign:"center"}} >
                <button style={{position:"relative",margin:"auto",textAlign:"center"}} onClick={this.forgot_password_popup}>Forgot Password?</button>
                {
                this.state.forgot_password_popup && 
                <div>
                    <input style={{textAlign:"center"}} value={this.state.forgot_password_input}  onChange={this.handle_forgot_password} placeholder="Enter your Email"/>
                </div>
                }
                {   
                    this.state.forgot_password_popup && 
                    this.state.forgot_password_input &&
                     <button className="btn" style={{marginTop:"10px"}} onClick={this.reset_password_request}>
                        Send Email
                    </button>
                }
            </div>   
        </div>
    )
    }
}