
import React from 'react';
import {Home_icon,Dialpad_icon,Profile_icon,Directory_icon} from '../svgs/SVGs'

import FooterButton from './FooterButton'
export default class Footer extends React.Component {
    render() {
    return (
        <div id="Footer">
        <FooterButton state={this.props.state} id="home" img={Home_icon} handler = {this.props.handler}/>
        <FooterButton state={this.props.state} id="dialPad" img={Dialpad_icon} handler = {this.props.handler}/>
        <FooterButton state={this.props.state} id="directory" img={Directory_icon} handler = {this.props.handler}/>
        <FooterButton state={this.props.state} id="profile" img={Profile_icon} handler = {this.props.handler}/>
        </div>
    )
  }
}