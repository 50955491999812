import React from 'react';
import outgoing_call_icon from '../../images/outgoing_call_icon.png';
import incoming_call_icon from '../../images/incoming_call_icon.png';
import missed_call_icon from '../../images/missed_call_icon.png';
import call_transfer_img from '../../images/call_transfer.png';

export default class GroupCallEntry extends React.Component {
  render() {
    let disposition_icon,call_target
    if (this.props.call.direction === "OUTGOING") {
      disposition_icon = outgoing_call_icon
      this.props.call.dstdid? call_target = this.props.call.dstdid : call_target = this.props.call.dstext
    } else {
      this.props.call.srcdid? call_target =  this.props.call.srcdid : call_target =  this.props.call.srcext
      if (this.props.call.disposition === "ANSWER") {
        disposition_icon = incoming_call_icon
      } else {
        disposition_icon = missed_call_icon
      }
    }

    return (
    <tr className="table_row" style={{height:"60px"}}>
      <td id="call_entry_disposition">
        <img alt="call" onClick={()=>{window.phone.call(call_target.replaceAll(" ",""), this.props.call_options)}} style={{cursor:"pointer"}} src={disposition_icon} width="30px"></img>
        {this.props.call.transferred?<img src={call_transfer_img} title="Transferred" alt="Transferred" width="33px"style={{paddingLeft:"1em"}}/> : ""}
      </td>
      <td id="call_entry_group">
          {this.props.groupname? this.props.groupname: ""}
      </td>
      <td id="call_entry_source">
          {this.props.call.srcdid? this.props.srcdidname : this.props.srcextname}
      </td>
      <td id="call_entry_destination" style={{height:"1.5em",verticalAlign:"middle"}}>
          {this.props.call.dstdid? this.props.dstdidname : this.props.dstextname}
      </td>
      <td id="call_entry_timestamp">
          {new Date(this.props.call.initialization).toLocaleString()}
      </td>
      <td id="call_entry_duration">
          {this.props.call.duration}
      </td>
    </tr>
    );
  }
}
