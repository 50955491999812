
import React from 'react';
import Mic from './svgs/SVGs'
import {Hangup} from './svgs/SVGs'
import {Handset} from './svgs/SVGs'
import incoming_img from '../images/incoming.png';
import outgoing_img from '../images/outgoing.png';
import call_transfer_img from '../images/call_transfer.png';
import on_hold_img from '../images/onHold.png';
import on_hold_img_active from '../images/onHoldOn.png';
import Draggable from 'react-draggable';
import { MainContext } from '../contexts/MainContext';
import { get_internal_name } from '../utils';


export default class Session extends React.Component {
    static contextType = MainContext

    constructor() {
      super();
      this.state = 
      {
        call_started:false,
        hold :false,
        status:'Calling',
        accepted:false,
        timer:"00:00:00",
        position: 
        {
          x: 0, 
          y: 0
        },
        activeDrags: 0,
        mic_color:"none",
        speaker_color:"none"
      }
    }
    timer = () =>  {
        let time = 0;
        const increment = () => {
            time +=1;
            this.setState({timer:formatter(time)})
            
        };
          
        this.timer_interval = setInterval(increment, 1000);
        
        
        const formatter = (time) =>{
          let hours = Math.floor(time / 3600);
          let minutes = Math.floor((time - hours * 3600) / 60);
          let seconds = time - hours * 3600 - minutes * 60;
        
          hours = `${hours}`.padStart(2, '0');
          minutes = `${minutes}`.padStart(2, '0');
          seconds = `${seconds}`.padStart(2, '0');
        
          return hours + ':' + minutes + ':' + seconds;
        }
        return (formatter(time))
        }
        
    get_direction = ()=>{
      if (this.props.session.direction === "incoming")
      { 
          return <img src={incoming_img} alt="incoming" />
      }
      else 
      {
          return <img src={outgoing_img} alt="outgoing" />
      }
    }
    hangUp = () => {
        if(!this.props.session.isEnded())
        {this.props.session.terminate(this.props._id)}
    }
    onHold = () =>{
        if(this.state.hold === true)
        {this.props.session.unhold();}
        else
        {this.props.session.hold()}
        this.setState({hold:!this.state.hold, status:this.props.session.remote_identity.uri.user})
    }
    muteIn = () =>{
        console.log('make mute in work')
    }
    muteOut = () => {
        console.log("mute")
        if (this.props.session.isMuted().audio) {
            console.log("muted")
            this.props.session.unmute({audio: true});
            this.setState({mic_color:"none"})
        } else {
            this.props.session.mute({audio: true});
            this.setState({mic_color:"orange"})
        }
    }
    pickup = () => 
    {         
        this.props.session.answer(this.context.call_options)
        this.props.session.connection.addEventListener('addstream', function (e) 
        {
            console.log("Stream added");
            var remoteAudio = document.createElement('audio');
            // Rick says: createObjectURL() is deprecated and removed from Chrome 71.
            // Streams should be assigned to HTMLMediaElement.srcObject
            try {
                remoteAudio.srcObject = e.stream;
            } catch (error) {
                remoteAudio.src = window.URL.createObjectURL(e.stream);
            }
            remoteAudio.play();
        });
    }
    handleDrag = (e, ui) => {
        this.setState({
          position: {
            x: 0,
            y: ui.y
          }
        });
      };
      onStart = () => {
        this.setState({activeDrags: this.state.activeDrags +1});
      };
    
      onStop = () => {
        this.setState({activeDrags: this.state.activeDrags -1});
      };
      onDrop = (e) => {
        this.setState({activeDrags: this.state.activeDrags -1});
        if (e.target.classList.contains("drop-target")) {
          alert("Dropped!");
          e.target.classList.remove('hovered');
        }
      };
      onDropAreaMouseEnter = (e) => {
        if (this.state.activeDrags) {
          e.target.classList.add('hovered');
        }
      }
      onDropAreaMouseLeave = (e) => {
        e.target.classList.remove('hovered');
      }
    get_name = (number) =>{
      try{
        if (parseInt(number.slice(-10))){
          return get_internal_name(number,this.context.groups,this.context.team,this.context.user.contacts,this.context.phones)
        }
        else
        {
          return number
        }
        }
        catch (e){
          console.log(e)
          return number
        }

    }
    componentDidMount()
    {
      if (this.props.session.direction !== "incoming")
      { 
        this.props.session._connection.addEventListener('addstream',  (e) => 
        {
          var remoteAudio = document.createElement('audio');             
          try {
              remoteAudio.srcObject = e.stream;
          } 
          catch (error) {
              remoteAudio.src = window.URL.createObjectURL(e.stream);
          }
          remoteAudio.play();
        }); 
          
      }
      setTimeout(()=>{this.props.index && this.setState({position: 
      {
        x: 0, 
        y: this.props.index*-100
      }})},200)
      this.props.session.on("ended", (e)=> {
          console.log('ended')
          console.log(e)
          this.props.terminate(this.props.session._id)

      }
      )
      this.props.session.on("failed", (e)=> {
          console.log('failed')
          console.log(e)
          console.log(this.props)
          if (this.props.ringtone)
          {this.props.ringtone.pause();
          }
          this.props.terminate(this.props.session._id)
      })
      this.props.session.on("connecting", (e)=> {
          console.log('connecting')
          //console.log(e)
          this.setState({status:"Connecting"})
      })
      this.props.session.on("sending", (e)=> {
           console.log('sending')
          console.log(e)
      })
      this.props.session.on("unhold", (e)=> {
            console.log('unhold')
            //  console.log(e)
          })
      this.props.session.on('progress', (e)=>{
        console.log('progress')
        console.log(e)
        if (e.originator === 'remote')
        {
          if (e.response.status_code === 180)
          {
            this.setState({status:"Ringing"})
            this.props.ringtone.play()
          }
        }
      })
      this.props.session.on("confirmed", (e)=> {
        console.log('confirmed')
        console.log(e)
          if (this.props.ringtone)
          {
          this.props.ringtone.pause();
          }
          this.setState({'call_started':true})
          const timer = this.timer()
          this.setState({timer:"00:00:01"})
          this.setState({accepted:true, status:"Answered",timer:timer})
          setTimeout(()=>{
            this.setState({"status":this.props.session.remote_identity.uri.user})
          },2000)
      })
      this.props.session.on("update", (e)=> {
           console.log("update")
           console.log(e)
      })
      this.props.session.on("peerconnection:createofferfailed", (e)=> {
          console.log('peerconnection:createofferfailed')
           console.log(e)
      })
      this.props.session.on("accepted", (e)=> {
          console.log('accepted')
          console.log(e)
      })

      this.props.session.on("sdp", (e)=> {
          console.log('sdp')
          console.log(e)
      })
      this.props.session.on("peerconnection", (e)=> {
          console.log('peerconnection')
          console.log(e)
      })
    }
    transfer_call = () =>{

      this.context.update_global('selected_call',this.props.session)
      setTimeout(this.context.update_global('transfer_call_modal',!this.context.transfer_call_modal),20)
      
    }
    get_inbound_destination =()=>{
      if(this.props.session._request.headers.Diversion)
      {
        const number =  this.props.session._request.headers.Diversion[0].raw.split('@')[0].slice(-10) //international flag
        const phone = this.context.phones.find(o => o.number === number)
        if(phone) {
          return "-> " + (phone.name || this.get_name(phone.number))
        }
        else {
          return "-> " + this.get_name(number)
        }
      }
      else{
        return ""
      }
    }
    componentDidUpdate(){
      // Stay on screen
      if (this.state.position.y > (this.session_div.offsetHeight-50))
      {
        this.setState({position:{
          x:this.state.position.x,
          y:0
        }})
      }
      if (this.state.position.y + window.innerHeight < 50){
        this.setState({position:{
          x:this.state.position.x,
          y:-window.innerHeight+120
        }})
      }
    }
    componentWillUnmount()
    {
      if (this.props.ringtone)
      {
        this.props.ringtone.pause()
      }
      clearInterval(this.timer_interval)
    }
    render() {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
         
    return(
        <Draggable axis="y" cancel=".no-cursor" onDrag={this.handleDrag} position={this.state.position}  {...dragHandlers}>
          <div style={{bottom:0}} className="session" id={this.props._id} ref={(el) => { this.session_div = el; }} >
            { 
            !this.state.call_started && this.props.session.direction ==="incoming" ? 
            <span>
              <p className="incomingCallStatus"> {`${this.get_name(this.props.session.remote_identity.uri.user)} ${this.get_inbound_destination()}`} </p>
              <div>
              <button className="answerBtn no-cursor"  onClick={this.pickup}>
                <Handset height="40px" width="40px" />
              </button> 
              <button className="hangupBtn no-cursor" onClick={this.hangUp}>
                <Hangup />
              </button>
              </div>
            </span>
            :
            <span>
              <div className="call_info">
                <p className="callStatus">{this.get_name(this.state.status)} </p>
                <p className="call_direction">{this.get_direction()}</p>
                <p className="timer" id={`timer-${this.props._id}`}>{this.state.timer}</p>
              </div>
              <div>
                <button className="muteInBtn no-cursor" onClick={this.transfer_call}>
                  <img src={call_transfer_img} alt="transfer" />
                </button>
                <button className="muteInBtn no-cursor" onClick={this.muteOut}>
                  <Mic fill={this.state.mic_color} />
                </button>
                <button className="muteInBtn no-cursor" onClick={this.onHold}>
                <img src={this.state.hold ? on_hold_img_active : on_hold_img} alt="hold" />
                </button>
                {/* <button className="muteInBtn no-cursor" onClick={this.muteIn}>
                  <Speaker fill={this.state.speaker_color} />
                </button> */}
                <button className="hangupBtn no-cursor" onClick={this.hangUp}>
                  <Hangup />
                </button>
              </div>
            </span>
            }
          </div>
        </Draggable>
        
     
    )
  }
}