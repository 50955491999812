import React from 'react';
import ConferenceCall from './ConferenceCall'
import TransferCall from './TransferCall';

export default class CallOperator extends React.Component
{
  constructor()
  {
    super();
    this.state = 
    {

    }
  }
    
  render() 
  {
    return (
      <div className="notifier" id="call_operator">
        <ConferenceCall sessions = {this.props.sessions} />
        <TransferCall />
      </div>
    );
  }
}
