import React from 'react';
import UserStatus from '../UserStatus';
import {Handset} from '../svgs/SVGs'
import {OpenText} from '../svgs/SVGs'
import { MainContext } from '../../contexts/MainContext';

export default class TeamMember extends React.Component {
  static contextType = MainContext
  
  makeCall = ()=>{
    window.phone.call(this.props.number.replaceAll(" ",""),this.context.call_options)
  }

  render() {
    return (
      <div className="contact">
      <UserStatus name={this.props.name} last_seen={this.props.last_seen} status={this.props.status} />
        <div className="connect"> 
          <button  onClick={this.makeCall}> 
          <Handset height="40px" width="40px" />
          </button>
          <button onClick={()=> (this.props.go_to_conversation(this.context._id,[this.props._id]))}> 
            <OpenText />
          </button>
        </div>
      </div>
    );
  }
}
