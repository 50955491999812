import React from 'react';
import TeamMember from './TeamMember'
import searchImg from '../../images/search.png'
import TeamContactToggle from '../TeamContactToggle';
import Contact from './Contact'
import { MainContext } from '../../contexts/MainContext';
import * as requests from '../../requests'

export default class Directory extends React.Component {
  static contextType = MainContext
  constructor() {
    super();
    this.state = {
      isLoaded:false,
      searchBar:false,
      items:"",
      filter:"",
      contacts:[],
      new_contact_form:false,
      new_contact:{"name":"","number":"","email":""}

  }
  this.filterContacts = this.filterContacts.bind(this);
  this.showHideSearch = this.showHideSearch.bind(this);
}
  componentDidMount(){
    document.addEventListener('click',(e)=>{
      try{
        const classes = e.target.className
        const parentClasses = e.target.parentElement.className;
        if (classes.includes("new_contact_form") || parentClasses.includes("new_contact_form")) {
        this.setState({new_contact_form:true})
      }
      else{
        this.setState({new_contact_form:false})
      }

      }
      catch{}
      
    })
  }
  get_team = () => {
    const divs = []
    if (this.props.team)
    {for ( const user of this.props.team)
        { 
          if ((user.display_name.toLowerCase().includes(this.state.filter.toLowerCase()) || user.extension.includes(this.state.filter))) 
          {
            divs.push(
              <TeamMember key={user._id} _id ={user._id} status={user.status} go_to_conversation={this.props.go_to_conversation} last_seen ={user.check_in.app} number={user.extension} name={user.display_name || user.first_name} message={this.props.message} />
            )
          }
        }}
    else {
      divs.push(<div key="loading"> <br/> LOADING</div>)
    }
    
    return divs
  }
  get_contacts = () => {
    const divs = [<button key="btn" className="new_contact_form"  onClick={()=>this.setState({new_contact_form : !this.state.new_contact_form})} id="new_contact">New Contact</button>,
     this.state.new_contact_form && 
     <form key="contact_form" className="new_contact_form" id="new_contact_form">
      <input autoComplete="off" type="text" onChange={(e)=>this.handle_new_contact_change(e.target.value,"name")} className="new_contact_input" value={this.state.new_contact.name} placeholder="Name"></input>
      <input autoComplete="off" type="number" onChange={(e)=>this.handle_new_contact_change(e.target.value,"number")}className="new_contact_input" value={this.state.new_contact.number} placeholder="10 Digit Number"></input>      
            <button id="new_contact_add_btn" onClick={(e) => this.submit_new_contact_form(e)} className="btn" >
              ADD
            </button>
    </form>]
    for ( const contact of this.props.contacts.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1))
        { divs.push(
            contact.name.toLowerCase().includes(this.state.filter.toLowerCase()) || contact.number.includes(this.state.filter) ?  
            <Contact key={contact.number} number={contact.number} name={contact.name} go_to_conversation={this.props.go_to_conversation} message={this.props.message} />
            :"")
          }
    return divs
  }
  showHideSearch(){
    this.setState({searchBar:!this.state.searchBar})
    setTimeout(()=>{
      if (this.state.searchBar)
      {this.search_input.focus()}
      else{
        this.setState({"filter":""})
      }
    },30)

  }
  filterContacts(event) {
    this.setState({filter: event.target.value});
  }
  handle_new_contact_change = (value,option) => {
    let new_contact = this.state.new_contact
    new_contact[option]=value
    this.setState({
          new_contact:new_contact
    })
  }
  submit_new_contact_form = (e)=>{
  e.preventDefault()
  if(this.state.new_contact.number.toString().length !== 10) {
    return (
    alert("Number needs to be 10 digits (International number option will be added shortly)")
    )
  }
  if(this.state.new_contact.name.length < 1) {
    return (
    alert("Contact needs to have a Name")
    )
  }
  requests.new_contact(this.state.new_contact).then(data=>{
    this.setState({new_contact_form:false})
    this.props.update_global("contacts",data.contacts)
    if (data.error) {
      alert(data.error)
    }
  })
  }
  render() {
    return (
      <div className="directory">
        <div id="directoryHeader">
        <button onClick={this.showHideSearch } className="directory_type" style={{position: "absolute",left:0}}> <img  style={{height: "60%"}} src={searchImg} alt="Search"></img></button>
        {
        this.state.searchBar && <input id="directory_search_input" ref={(el) => { this.search_input = el; }} onChange={this.filterContacts} value={this.state.filter}  className="input" style={{position: "absolute",left:"60px"}} type="text" name="name" />
        }
        <span style={{position:"relative",top:"10px"}}>
        <TeamContactToggle status={this.props.team_or_contacts} team_contact_toggle={this.props.team_contact_toggle} />
        </span>
        </div>
        <div className="contacts_container">
          {  
          this.props.team_or_contacts?
          this.get_team() :
          this.get_contacts()
          }
          
        </div>
      </div>
    );
  }
}