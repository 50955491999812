export default function Mic (props) {
    return(
<svg  xmlns="http://www.w3.org/2000/svg" fill={props.fill} stroke="orange" strokeWidth="6" height="40px" width="40px" viewBox="0 0 111.54 194.36">
    <path className="cls-1" d="M135.05,73.5q.15,21.63,0,42.82c-3.71,16.81-19.46,28.54-36.64,27.86A35.94,35.94,0,0,1,65,117.35c-.9-14-1.47-28.59-1.62-43.85q-.18-19.8.54-38.17c1.53-18.58,18.09-32.62,36.62-32,17.26.59,32.05,13.75,34,30.94Q134.91,53.71,135.05,73.5Z" transform="translate(-44.17 -2.82)"/><path className="cls-1" d="M81.23,149.22a45,45,0,0,1-19.81-18.9A46.1,46.1,0,0,1,56,113.9L56.19,92l-11.28-.23-.24,21.93a62.09,62.09,0,0,0,5,17.52c2.44,5.35,7.93,17,20.92,25a52.55,52.55,0,0,0,25.25,7.65q-.11,10.57-.2,21.14l-32.79-.22q.1,6,.23,11.92h73.66q.1-6,.23-11.92l-32.8.22q-.09-10.57-.2-21.14a52.55,52.55,0,0,0,25.25-7.65c13-8,18.48-19.65,20.92-25a62.17,62.17,0,0,0,5-17.52L155,91.74h.36L143.57,92q.12,11,.25,21.93a39.86,39.86,0,0,1-4.51,17.1c-6.07,11.5-16.09,16.44-19.36,18C118,149.92,100,158.12,81.23,149.22Z" transform="translate(-44.17 -2.82)"/>
</svg>
)
}

export function Speaker (props) {
    return(
<svg   xmlns="http://www.w3.org/2000/svg" fill={props.fill} stroke="orange" height="40px" width="40px" strokeWidth="7" viewBox="0 0 150 150">
<g id="speaker">
<path className="cls-1" d="M99.93,163.57c.14-42.43-.14-84.86,0-127.28L65.21,68H29.64c.07,20.71-.07,41.43,0,62.14" transform="translate(-29.12 -35.15)"/>
<path className="cls-1" d="M99.93,36.29c.14,42.42-.14,84.85,0,127.28L65.21,131.86H29.64c.07-20.72-.07-41.43,0-62.15" transform="translate(-29.12 -35.15)"/>
<path className="cls-1" d="M138.14,36.07c3.49,3,28.19,25.13,27.57,61.29-.57,33.38-22.3,53.81-26.05,57.21" transform="translate(-29.12 -35.15)"/>
<path className="cls-1" d="M127.25,50c2.64,2.26,21.87,19.33,21.32,47.36-.52,26.45-18.26,42.33-21.1,44.78" transform="translate(-29.12 -35.15)"/>
<path className="cls-1" d="M117.5,66.29C119,67.53,133,79.55,131.86,98.86c-1,16.55-12.41,26.25-14.36,27.85" transform="translate(-29.12 -35.15)"/>
</g>
</svg>
)
}

export  function Hangup () {
    return(
<svg  xmlns="http://www.w3.org/2000/svg"  fill="none" height="40px" width="40px" stroke="red" strokeWidth="6" viewBox="17 -3 120 165">
<g id="endCall">
    <path className="cls-1" 
    d="M56.21,129.5l22.72-18.43-2.79-4.5a23.83,23.83,0,0,1,3-3.21c7.35-6.53,16.8-6.6,22.5-6.65,5.31,0,12.44-.09,19.29,4.5a25.66,25.66,0,0,1,4.71,4.08l-3.43,5.78L143,130.79a21.37,21.37,0,0,0,12.21-6.11c1-1,6.33-6.69,5.89-14.77-.08-1.36-.43-4.93-3.74-10.2-4.92-7.81-11.94-12.36-17.57-15.21-3.94-2-21.25-10.3-46.93-8.14-10.48.88-24.4,2.23-37.29,12-4.63,3.5-13.25,10-14.14,20.35a19.78,19.78,0,0,0,4.07,14.36A19.29,19.29,0,0,0,56.21,129.5Z" transform="translate(-25.08 -27.89)"/>
    <circle className="cls-1" cx="76.25" cy="76.25" r="75.75"/>
</g>
</svg>
)
    }

export  const Handset =(props) =>{

        return(
    <svg  xmlns="http://www.w3.org/2000/svg" style={{pointerEvents:"none"}}  pointerEvents="none" fill="none" height={props.height} width={props.width} stroke="orange" strokeWidth="6" viewBox="17 -3 120 165">
    <path xmlns="http://www.w3.org/2000/svg" id="phone" className="cls-1" d="M40.54,46.31a33.1,33.1,0,0,1,5.07-6.49,31.85,31.85,0,0,1,10.6-7.07,19.26,19.26,0,0,1,5-1.4,18.16,18.16,0,0,1,7.82.76A62,62,0,0,1,79.36,48.82c2.26,5.49,2.79,10,3.86,19a8.46,8.46,0,0,1-1,5.71,7.8,7.8,0,0,1-2.6,2.33,32.33,32.33,0,0,0-6.72,6,54.66,54.66,0,0,0,6.11,19c6,11.2,14.44,17.19,19.28,20.57a70.69,70.69,0,0,0,23.47,10.61l4.34-5.74,5-5.51h7.45a72.69,72.69,0,0,1,7.87.89,78.18,78.18,0,0,1,10.71,2.65c4.75,1.51,7.12,2.27,8.36,3.2,4.48,3.38,6.85,10,5.79,15.75-.53,2.83-2.65,5.6-6.88,11.13s-6.7,7.15-7.91,7.84a20.21,20.21,0,0,1-7.68,2.45c-1.61,0-3.83.07-6.48,0a119,119,0,0,1-14.13-1.47c-18.16-3.15-34.39-12.54-34.39-12.54-21.42-12.48-32.57-29.3-38.57-38.57-10.76-16.63-14.66-32.51-16.4-39.4-.58-2.27-2.74-6.55-2.56-12.35A30,30,0,0,1,40.54,46.31Z" transform="translate(-35.78 -30.71)"/>
    </svg>
    )
        } 

export  function OpenText () {
    return(
<svg  xmlns="http://www.w3.org/2000/svg" fill="none"  height="40px" width="40px" stroke="orange" strokeWidth="6" viewBox="17 -13 100 145">
<g xmlns="http://www.w3.org/2000/svg" id="text"><path className="cls-1" d="M159.71,133.36h-44l-28.29,28q.17-14,.32-28H52.36V56.54H159.71Z" transform="translate(-51.86 -56.04)"/><circle className="cls-1" cx="54.14" cy="40.68" r="6.43"/><circle className="cls-1" cx="76.8" cy="40.68" r="6.43"/><circle className="cls-1" cx="31.06" cy="40.68" r="6.43"/></g></svg>
)
    }

export  function Home_icon (props) {
    return(
<svg  xmlns="http://www.w3.org/2000/svg"   height="40px" width="40px" fill={props.fill} stroke="orange" strokeWidth="6" viewBox="17 -13 150 185">
<path xmlns="http://www.w3.org/2000/svg" id="house" className="cls-1" d="M121.58,134.32H81.08l1,45.91-45-.75-1-75.21h-26l91.61-94.5h-.47l91.6,94.5h-26q-.48,37.61-1,75.21l-45,.75q.48-22.95,1-45.91H81.29" transform="translate(-8.88 -9.27)"/>
</svg>)
    }
export  function Dialpad_icon (props) {
    return(
<svg  xmlns="http://www.w3.org/2000/svg"   height="40px" width="40px" fill={props.fill} stroke="orange" strokeWidth="6" viewBox="0 -8 140 185">
<g xmlns="http://www.w3.org/2000/svg" id="dialpad"><circle className="cls-1" cx="16.17" cy="16.17" r="15.67"/><circle className="cls-1" cx="64.54" cy="16.17" r="15.67"/><circle className="cls-1" cx="16.17" cy="63.62" r="15.67"/><circle className="cls-1" cx="16.17" cy="111.08" r="15.67"/><circle className="cls-1" cx="111.39" cy="16.17" r="15.67"/><circle className="cls-1" cx="64.54" cy="63.62" r="15.67"/><circle className="cls-1" cx="111.39" cy="63.62" r="15.67"/><circle className="cls-1" cx="64.54" cy="111.08" r="15.67"/><circle className="cls-1" cx="111.39" cy="111.08" r="15.67"/><circle className="cls-1" cx="64.54" cy="158.53" r="15.67"/></g></svg>)
    }

export  function Profile_icon (props) {
    return(
<svg  xmlns="http://www.w3.org/2000/svg"   height="40px" width="40px" fill={props.fill} stroke="orange" strokeWidth="6" viewBox="1 -23 150 175">
<g xmlns="http://www.w3.org/2000/svg" id="profile"><circle className="cls-1" cx="58.57" cy="28.57" r="28.07"/><path className="cls-1" d="M163.57,160H47.43a58.07,58.07,0,0,1,116.14,0Z" transform="translate(-46.93 -39.51)"/></g></svg>)
    }


export  function Directory_icon (props) {
    
            return(
        <svg  xmlns="http://www.w3.org/2000/svg"   height="40px" width="40px"  fill={props.fill} stroke="#Ff8200" strokeWidth="10" viewBox="120 -50 200 605">
           <g  xmlns="http://www.w3.org/2000/svg" id="directory">
               <path id="XMLID_5_" d="M256,0C114.5,0,0,114.5,0,256s114.5,256,256,256s256-114.5,256-256S397.5,0,256,0z M431.9,324.9
                   c-9.3-3.7-28.9-4.7-36.3-13c-3.7-4.7-0.9-20.5-0.9-20.5c30.7-1.9,38.2-7.4,38.2-7.4c4.7-2.8,2.8-3.7,0-7.4
                   c-13-22.3-7.4-51.2-8.4-75.4c-0.9-20.5-5.6-42.8-24.2-54.9c-9.3-5.6-24.2-8.4-39.1-8.4c-12.1,0-23.3,1.9-30.7,4.7
                   c-52.1,20.5-21.4,90.3-39.1,129.4c-2.8,6.5-7.4,9.3,0,13c0,0,9.3,5.6,35.4,7.4c0,0,0,15.8,0,16.8c-0.9,5.6-14.9,10.2-19.5,11.2
                   c-3.7,0.9-11.2,2.8-20.5,7.4c-4.7,1.9-3.7,8.4,0.9,9.3c4.7,0.9,10.2,1.9,14,3.7c0,0,32.6,7.4,56.8,30.7
                   c15.8,15.8,22.3,33.5,24.2,54.9c0.9,5.6-1.9,11.2-6.5,14l-7.4,4.7c-2.8,0.9-5.6,0-5.6-2.8c0-36.3-22.3-68-56.8-80.1
                   c-16.8-5.6-34.4-6.5-51.2-12.1c-4.7-1.9-14.9-3.7-16.8-9.3s-1.9-12.1-2.8-18.6c0-4.7,0-9.3,0-14c0-2.8,7.4-9.3,9.3-12.1
                   c6.5-11.2,7.4-26.1,8.4-38.2c11.2,2.8,12.1-16.8,14-23.3c0.9-3.7,6.5-26.1-2.8-28.9c2.8-5.6,4.7-12.1,5.6-17.7
                   c2.8-15.8,3.7-32.6-0.9-48.4c-10.2-31.7-41-50.3-72.6-51.2c-32.6-0.9-66.1,14.9-78.2,46.5c-5.6,15.8-5.6,32.6-3.7,48.4
                   c0.9,7.4,1.9,15.8,5.6,22.3c-7.4,3.7-5.6,21.4-3.7,26.1c1.9,6.5,3.7,28.9,14.9,26.1c0.9,10.2,1.9,20.5,4.7,29.8
                   c1.9,6.5,5.6,12.1,10.2,16.8c1.9,2.8,3.7,2.8,2.8,5.6c0,9.3,0,19.5-2.8,28.9c-2.8,9.3-23.3,13-31.7,14.9
                   c-18.6,3.7-35.4,5.6-51.2,14c-20.5-33.5-30.7-72.6-30.7-113.6c0-58.6,23.3-115.4,65.2-157.3c41-41,95.9-64.2,153.6-64.2
                   c58.6-0.9,115.4,22.3,157.3,63.3c42.8,41.9,66.1,98.7,66.1,158.3c0,30.7-6.5,60.5-17.7,87.5C453.4,335.1,443.1,328.6,431.9,324.9
                   L431.9,324.9z"/>
           </g>
           </svg>
        )
}