import React from 'react';
import conference_img from '../../images/conference_call_icon.png'
const _ = require('lodash');

export default class ConferenceCall extends React.Component {
  constructor()
  {
    super();
    this.state = 
    {
      mix_calls_modal:false,
      mixed_sessions:[]
    }
  }
  mix_calls_btn = ()=>{

    let divs =[]
    if (this.props.sessions.length>1) 
    {
      divs.push(<button key="conference_btn" className="btn" id="conference_btn" onClick={()=>this.setState({mix_calls_modal:!this.state.mix_calls_modal})} > <img src={conference_img} alt="conference"/> </button>)
    }
    return divs
  }
  componentDidUpdate() {
    let updated_mixed_sessions = []
    for (const session of this.state.mixed_sessions)
    {
      if (_.includes(this.props.sessions,session))
      {
        updated_mixed_sessions.push(session)
      }
    }
    if (!_.isEqual(this.state.mixed_sessions,updated_mixed_sessions))
    this.setState({mixed_sessions:updated_mixed_sessions})

  }
  mix_calls_modal = () => 
  {
    const get_sessions = () => 
    {
      let divs = []
      for (const session of this.props.sessions)
      {
          if (_.includes(this.state.mixed_sessions,session))
          {
            divs.push (
              <div style={{padding:"5px"}}>
              <label key={session._id}>
              <input className="call_mixing_input" checked readOnly type='checkbox'  value={session._id}  />
                  {session.remote_identity.display_name || session.remote_identity.uri.user }
              </label>
              </div>
              )
          }
          else {
            divs.push (
              <div style={{padding:"5px"}}>
              <label key={session._id}>
              <input className="call_mixing_input" type='checkbox' value={session._id}  />
                  {session.remote_identity.display_name || session.remote_identity.uri.user }
              </label>
              </div>
            )
          }
          
          
      }
      return divs
    }
    const apply_call_mixing = () =>{
      const calls_to_mix = document.getElementsByClassName('call_mixing_input')
      let new_mixed_sessions =[]
      for (const call of calls_to_mix)
      {
        if (call.checked)
        { 
          
          const call_session = this.props.sessions.find((obj)=> obj._id === call.value)
          if ( call_session!==undefined)
            { 
              new_mixed_sessions.push(call_session)
            }
        }
      }
      let sessions_to_mix = []
      for (const session of new_mixed_sessions)
      {
        sessions_to_mix.push(session)  //Need to improve the session props
      }
      this.conference(sessions_to_mix)
      this.setState({mixed_sessions :new_mixed_sessions})
      this.setState({mix_calls_modal:false})
    }




    return (
    this.state.mix_calls_modal && this.props.sessions.length>1 &&
    <div id="mix_calls_modal">
      {get_sessions()}
      <button onClick={apply_call_mixing} style={{padding:"10px",margin:"10px"}}  className="btn">
        Conference
      </button>
    </div>)
  }
  
  render() 
  {
    return (
      <div id="conference" style={{width:"20px"}}>
        {this.mix_calls_btn()}
        {this.mix_calls_modal()}
      </div>
    );
  }


// sessions is an array of JsSIP.RTCSessions
 conference =(sessions) =>
 {
    // grab all the received tracks for each session
    var receivedTracks = [];
    sessions.forEach(function(session) 
    {
      if(session !== null && session !== undefined) {
              // getReceivers = array of RTCRtpReceiver objects
          session.connection.getReceivers().forEach(function(receiver) {		
                  // track = single media track within a stream	
            receivedTracks.push(receiver.track);
          });
      }
    });

    // AudioContext() = Web Audio API (https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API)
      // Used to mix the received tracks
    var audioContext = new AudioContext();	
    var allReceivedMediaStreams = new MediaStream();

    sessions.forEach(function(session) {	
      if(session !== null && session !== undefined) {

          var mixedOutput = audioContext.createMediaStreamDestination(); // create a new media stream

          session.connection.getReceivers().forEach(function(receiver) {
            receivedTracks.forEach(function(track) {
                      // allReceivedMediaStreams = ALL tracks added for local playback
              allReceivedMediaStreams.addTrack(receiver.track);
              if(receiver.track.id !== track.id) {
                          // sourceStream = Don't add current iteration received track
                          // (so remote party won't hear themselves in the playback)
                  var sourceStream = audioContext.createMediaStreamSource(new MediaStream([track]));
                sourceStream.connect(mixedOutput);
              }
            });
          });
          // mix local audio with received tracks
          session.connection.getSenders().forEach(function(sender) {
            var sourceStream = audioContext.createMediaStreamSource(new MediaStream([sender.track]));
            sourceStream.connect(mixedOutput);
          });
              // replace session track with mixed track for playback to remote party
          session.connection.getSenders()[0].replaceTrack(mixedOutput.stream.getTracks()[0]);
      }
    });
    // playback ALL received streams
    var remoteAudio = document.createElement('audio'); // audio is hard coded, might be changed in react?
    remoteAudio.srcObject = allReceivedMediaStreams;
    var promiseRemote = remoteAudio.play();
    if(promiseRemote !== undefined) 
    {
      promiseRemote.then(_ => {
        console.log("playback all received streams");
      }).catch(error => {
        console.log(error);
      });
    }
 }
}