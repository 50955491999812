
export const get_user_from_id = (user_id , team) =>{
    try{
        return team.find( o=> o._id === user_id)
    }
    catch{
        return user_id 
    }
}


// international flag
export const format_number_for_user = (number) => {
    try{
        if (number.length >= 10){
            number = number.slice(-10)
            return `(${number.substr(0,3)}) ${number.substr(3,3)} ${number.substr(6)}`
        }
       else {
           return number
       }
    }
   catch{
       return number
   }
}

export const get_internal_name = (number,groups,team,contacts,phones) =>{
    if (number)
    {number = number.replaceAll("[\\s()-]", "")
    }
    try{
        let match
        match = contacts.find(o=>o.number === number ||o._id ===number ||"+" + o._id === number|| o.number === "+" + number|| o.number === "+1" + number )
        if (match){
            if (match.name !== "")
            {
                return match.name
            }
            else{
                return format_number_for_user(number)
            }
        }
        match = phones.find(o=>o.number === number ||o._id ===number ||"+" + o._id === number )
        if (match){
            if (match.name !== "")
            {
                return match.name
            }
            else{
                return format_number_for_user(number)
            }
        }
        match = team.find(o=>o.extension === number || o._id === number)
        if (match)
        {  
            if (match.display_name !== "")
            {
                return match.display_name
            }
            else{
                return number
            }
        }
        match = groups.find(o=>o._id === number)
        if (match)
        {
            if (match.name !== "")
            {
                return match.name
            }
            else{
                return number
            }
        }
        return format_number_for_user(number)
    }
    catch{
        return format_number_for_user(number)
    }
    
}


export function formatDuration(duration) {
    if (duration ==="00:00"){
        duration = "00:00:00" // fix on backend
    }

    const timeParts = duration.split(':').map(Number);
    const totalSeconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];
    
    if (totalSeconds < 60) {
      return `${totalSeconds}s`;
    } else if (totalSeconds < 3600) {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes}m ${seconds}s`;
    } else {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      return `${hours}h ${minutes}m`;
    }
  }

  export const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours24 = date.getHours();
    const hours12 = hours24 % 12 || 12;
    const minutes = date.getMinutes();
    const amPm = hours24 < 12 ? 'AM' : 'PM';
    return `${hours12.toString()}:${minutes.toString().padStart(2, '0')} ${amPm}`;
  };