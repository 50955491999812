import React from 'react';

export default class FooterButton extends React.Component {
  get_fill=()=>{
    let fill
    this.props.state[this.props.id]===true?
    fill = "orange"
    :
    fill = "none"
    return fill 
  }
  render() {
    return (
        <button onClick={()=> this.props.handler(this.props.id) }  >
        <this.props.img fill = {this.get_fill()} />
        </button>
    )
  }
}