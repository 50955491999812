import React from 'react';
import { MainContext } from '../../contexts/MainContext';
import * as utils from '../../utils'

export default class MessageBubble extends React.Component {
    static contextType = MainContext
    constructor() {
        super();
        this.state = {
        
      }
    }
    get_author = ()=>{
    let author = utils.get_user_from_id(this.props.author,this.context.team)
    if (author)
    
    {
      return `${author.display_name}:`
    }
    else
    {
      return ""
    }
    }
    componentDidMount(){
      }

    make_sms_bubble = () =>{
    let div=[]
    let classname 
    this.props.src === this.context.outbound_text ?
    classname = "my_text":
    classname = "their_text"
    
      return(
      <div className={classname}>
      <p className={this.props.pending?"not_arrived":""}> {`${this.get_author()} ${this.props.body}`}
        <i className="timestamp">
        {(this.props.date && new Date(this.props.date).toLocaleString()) || "Sending"}
        </i>
      </p>
      </div>
      )      
    }
    make_chat_bubble = ()=>
    {
    let classname 
    this.props.author === this.context._id ?
    classname = "my_text":
    classname = "their_text"

    if (this.props.author !== this.context._id  && this.props.number_of_endpoints > 2 ) 
      {
      return(        
        <div className={classname}>
        <p className="text_bubble" >{`${this.get_author()} ${this.props.body}`}
          <i className="timestamp">
          {new Date(this.props.date).toLocaleString()}
          </i>
        </p>
        </div>
        )
        
      }
    else
      {
        return(
        <div className={classname}>
        <p className={this.props.pending?"not_arrived text_bubble":"text_bubble"} > {this.props.body}
          <i className="timestamp">
          {(this.props.date && new Date(this.props.date).toLocaleString()) || "Sending"}
          </i>
        </p>
        </div>
        )
      
      }
    }
    

  render() {      
    let return_fun
    this.context.conversation.carrier?
        return_fun = this.make_sms_bubble:
        return_fun = this.make_chat_bubble
 
    return (
      
        return_fun()
      
      
    );
  }
}
